import { useState } from "react";
import { Container } from "../Container/Container";
import { Spinner } from "../Spinner/Spinner";
import styles from "./styles.module.scss";

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputsState, setInputsState] = useState({
    email: "",
    error: "",
  });

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const sendEmail = async () => {
    if (!validateEmail(inputsState.email)) {
      return setInputsState({
        ...inputsState,
        error: "Make sure that your email address is entered correctly.",
      });
    }

    setIsLoading(true);
    // TODO: you need to replace sleep with a query
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    await sleep(3000);
    // TODO: you need to replace sleep with a query
    setIsLoading(false);
  };

  return (
    <div className={styles.contact} id={"contactForm"}>
      <Container>
        <h3 className={styles.contact__title}>
          And all of that is absolutely free with the open beta!
        </h3>
        <p className={styles.contact__desc}>Try the open beta!</p>

        <div className={styles.contact__form}>
          <input
            className={styles.contact__input}
            disabled={isLoading}
            type="text"
            onChange={(event) =>
              setInputsState({
                ...inputsState,
                email: event.target.value,
                error: "",
              })
            }
            placeholder="E-mail"
            maxLength={60}
            autoComplete={"email"}
          />

          <button
            className={`${styles.contact__button} ${
              isLoading && styles.contact__buttonLoading
            }`}
            onClick={sendEmail}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Send a request"}
          </button>
        </div>

        {inputsState.error && (
          <div className={styles.contact__error}>{inputsState.error}</div>
        )}
      </Container>
    </div>
  );
};
