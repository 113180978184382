import config from "../config/config";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: config.infuraId // required
    }
  }
};

const web3Modal = new Web3Modal({
  network: config.ethNetwork, // optional
  cacheProvider: true, // optional
  disableInjectedProvider: false,
  theme: "dark",

  providerOptions // required
});

const web3Uplink = async () => {
  let web3 = null;
  let connected = false;

  if (window.ethereum) {
    console.log("W3U: Using injected web3 instance");
    try {
      // Request account access if needed
      await window.ethereum.enable();
      // Acccounts now exposed
      web3 = new Web3(window.ethereum);
      connected = true;
    } catch (error) {
      // User denied account access..
      console.log("User denied access");
    }
  }
  // Legacy dapp browsers...
  if (!web3 && window.web3) {
    web3 = new Web3(window.web3.currentProvider);
    connected = true;
    console.log("W3U: Using legacy dapp browser");
  }
  // Non-dapp browsers...
  if (!web3) {
    web3 = new Web3(
      new Web3.providers.HttpProvider(config.infuraUrl)
    );
    connected = false
    console.log("W3U: No web3 instance, using Infura.io connection");
  }
  return {web3, connected};
}

const nftContract = (web3) => {
    return new web3.eth.Contract(config.nftAbi, config.nftAddress);
}

const ethNetwork = () => {
  return config.ethNetwork
}

const defaultGas = () => {
  return config.defaultGas
}

const exportedObject = {
  nftContract,
  web3Modal,
  web3Uplink,
  ethNetwork,
  defaultGas
};

export default exportedObject;
