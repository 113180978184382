let isDataLayerSubmitted = false;

export const utmContentFunc = () => {
    if (typeof window !== "undefined") {
        const utmData = {
            utmTerm: null,
        };

        // url parameter acquisition
        const getAllUrlParams = (url) => {
            const isBrowser = typeof window !== "undefined";
            const urlLocation = isBrowser
                ? window.location.search.slice(1)
                : undefined;
            var queryString = url ? url.split("?")[1] : urlLocation;
            var obj = {};
            if (queryString) {
                queryString = queryString.split("#")[0];
                var arr = queryString.split("&");
                for (var i = 0; i < arr.length; i++) {
                    var a = arr[i].split("=");
                    var paramNum = undefined;
                    // eslint-disable-next-line no-loop-func
                    var paramName = a[0].replace(/\[\d*\]/, function (v) {
                        paramNum = v.slice(1, -1);
                        return "";
                    });
                    var paramValue = typeof a[1] === "undefined" ? true : a[1];
                    paramName = paramName.toLowerCase();
                    // try {
                    //     paramValue = atob(paramValue);
                    // } catch (err) {
                    //     paramValue = paramValue;
                    // }
                    if (obj[paramName]) {
                        if (typeof obj[paramName] === "string") {
                            obj[paramName] = [obj[paramName]];
                        }
                        if (typeof paramNum === "undefined") {
                            obj[paramName].push(paramValue);
                        } else {
                            obj[paramName][paramNum] = paramValue;
                        }
                    } else {
                        obj[paramName] = paramValue;
                    }
                }
            }
            return obj;
        };

        // cookie add function
        const setCookie = (cookieName, cookieValue, days) => {
            const isBrowser = typeof window !== "undefined";
            if (!isBrowser) {
                return;
            }
            if (!cookieValue) {
                return;
            }
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            document.cookie =
                cookieName +
                " = " +
                // btoa(cookieValue) +
                cookieValue + 
                "; expires = " +
                date.toGMTString();
        };

        // cookie search function
        const getCookie = (name) => {
            const isBrowser = typeof window !== "undefined";
            if (!isBrowser) {
                return undefined;
            }
            var matches = document.cookie.match(
                new RegExp(
                    "(?:^|; )" + //eslint-disable-next-line
                        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                        "=([^;]*)"
                )
            );
            return matches ? matches[1] : undefined;
        };

        // utmData filling function
        const setUtmData = (utmString) => {
            var str = null;
            if (utmString) {
                let stringUncode = utmString;
                str = stringUncode
            } else {
                let utmCookie = getCookie("va_content");
                if (utmCookie) {
                    str = utmCookie
                }
            }
            if (str === null) {
                return;
            }
            utmData.utmTerm = str
        };

        // utmData stor initialization
        const initUtmStor = () => {
            // 1. get the parameter line from url
console.log('UTM_ALL_URL --->', getAllUrlParams())
            const utmString = getAllUrlParams().utm_term;

            // 2. add to the cookie with parameters utm_term
            setCookie("va_content", utmString, 30);

            // 3. utmData filling
            setUtmData(utmString);

            // 4. parameters from the utmObj are recorded in the dataLayer
            if (typeof window !== "undefined") {
                if (utmData.utmTerm) {
                    if (!isDataLayerSubmitted) {
                        window.dataLayer.push({
                            action: `term`,
                            value: `${utmData.utmTerm}`,
                        });
                    }
                    isDataLayerSubmitted = true;
                }
                window.utmUserData = utmData;
            }
console.log('UTM_DATA --->', utmData)
            return utmData;
        };
        // stor initialization
        try {
            return initUtmStor();
          } catch (err) {
            return
          }
    }
};

export const getUtmUserData = () => {
    let utmUserData = {};

    if (process.browser) {
        utmUserData = window.utmUserData ? window.utmUserData : {};
    }

    return utmUserData;
};
