import styles from "./styles.module.scss";
import { Container } from "../Container/Container";
import aboutBg from "../../assets/images/aboutBg.png";
import smokeBg from "../../assets/images/smokeBg.png";

export const AboutVideo = ({ name }) => {
  return (
    <div className={styles.aboutVideo} name={name} id="videos">
      <img className={styles.aboutVideo__bg} src={aboutBg} alt="Background" />
      <img className={styles.aboutVideo__smoke} src={smokeBg} alt="Smoke" />
      <Container>
        <div className={styles.aboutVideo__content}>
          <div className={styles.aboutVideo__content__desc}>
            <h2 className={styles.aboutVideo__title}>
              Any doubts? See how the cheat performs in-game!
            </h2>
            <a className={styles.aboutVideo__link} href="/#TODO">
              MORE VIDEOS
            </a>
          </div>
          <div className={styles.aboutVideo__content__item}>
            <div className={styles.aboutVideo__content__video}>
              <div
                className={styles.aboutVideo__content__iframe}
                id={"playVideo"}
              >
                <iframe
                  width="566"
                  height="435"
                  src="https://www.youtube.com/embed/7PIji8OubXU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
