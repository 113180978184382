import styles from "./styles.module.scss";
import { Container } from "../Container/Container";
import feature from "../../assets/images/feature.png";
import cardsBg from "../../assets/images/cardsBg.png";

export const Features = ({ name }) => {
  return (
    <div className={styles.features} id="features" name={name}>
      <img className={styles.features__bg} src={cardsBg} alt="Background" />
      <Container>
        <div className={styles.features__items}>
          <div className={styles.features__item}>
            <img className={styles.features__img} src={feature} alt="Feature" />
            <div className={styles.features__desc}>
              <h4 className={styles.features__title}>
                Projectile <br />
                Removal
              </h4>
              <p className={styles.features__text}>
                Tired of dying while flashed? Disable the flash bang and smoke
                effects and outplay your opponents.
              </p>
            </div>
          </div>
          <div className={styles.features__item}>
            <img className={styles.features__img} src={feature} alt="Feature" />
            <div className={styles.features__desc}>
              <h4 className={styles.features__title}>
                Projectile <br />
                Removal
              </h4>
              <p className={styles.features__text}>
                Tired of dying while flashed? Disable the flash bang and smoke
                effects and outplay your opponents.
              </p>
            </div>
          </div>
          <div className={styles.features__item}>
            <img className={styles.features__img} src={feature} alt="Feature" />
            <div className={styles.features__desc}>
              <h4 className={styles.features__title}>
                Projectile <br />
                Removal
              </h4>
              <p className={styles.features__text}>
                Tired of dying while flashed? Disable the flash bang and smoke
                effects and outplay your opponents.
              </p>
            </div>
          </div>
          <div className={styles.features__item}>
            <img className={styles.features__img} src={feature} alt="Feature" />
            <div className={styles.features__desc}>
              <h4 className={styles.features__title}>
                Projectile <br />
                Removal
              </h4>
              <p className={styles.features__text}>
                Tired of dying while flashed? Disable the flash bang and smoke
                effects and outplay your opponents.
              </p>
            </div>
          </div>
        </div>
        <a className={styles.features__link} href="/#TODO">
          Load more
        </a>
      </Container>
    </div>
  );
};
