import React from "react";

import { AboutVideo } from "../components/AboutVideo/AboutVideo";
import { Footer } from "../components/Footer/Footer";
import { Features } from "../components/Features/Features";
import { JoinDiscord } from "../components/JoinDiscord/JoinDiscord";
import { Header } from "../components/Header/Header";
import { Pager } from "../components/Pager/Pager";

export const HomePage = () => {
  return (
    <Pager>
      <Header showPager name="header" />
      <Features showPager name="about" />
      <JoinDiscord name="video" />
      <AboutVideo showPager name="video" />
      <Footer showPager name="footer" />
    </Pager>
  );
};
