import React, { useState, useRef, useEffect } from "react";
import discordImage from "../../assets/images/iconDiscordHeader.png";
import headerLogo from "../../assets/images/headerLogo.png";
import { Icon } from "../Icon/Icon";
import { ICONS } from "../../Constants/icons";
import styles from "./styles.module.scss";

export const HeaderMenu = () => {
  const menu = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const onClick = (e) => {
      if (menu.current !== null && menu.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };
    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, menu]);

  return (
    <nav className={`${styles.headerMenu} ${isActive && styles.activeMenu}`}>
      <div className={styles.headerMenu__mobile}>
        <a href="/">
          <img src={headerLogo} alt="Mobile logo" />
          <p>Boostware</p>
        </a>
        <button onClick={() => setIsActive(!isActive)}>
          <span />
          <span />
          <span />
          <span />
        </button>
      </div>
      <ul ref={menu} className={styles.headerMenu__list}>
        <li className={styles.headerMenu__item}>
          <a href="/#whyUs">Why Us</a>
        </li>
        <li className={styles.headerMenu__item}>
          <a href="/#videos">Videos</a>
        </li>
        <li className={styles.headerMenu__item}>
          <a href="/#features">Features</a>
        </li>
        <li className={styles.headerMenu__item}>
          <a href="/#contactForm">
            <span>Join Us!</span>
          </a>
        </li>
        <li className={styles.headerMenu__item}>
          <a
            href="/#TODO"
            target="_blank"
            rel="noreferrer"
            height="50px"
            className={styles.headerMenu__discord}
          >
            <img src={discordImage} alt="Join our Discord" />
            <Icon name={ICONS.discord} />
          </a>
        </li>
      </ul>
    </nav>
  );
};
