import styles from "./styles.module.scss";
import headerLogo from "../../assets/images/headerLogo.png";
import { Container } from "../Container/Container";
import { ContactForm } from "../ContactForm/ContactForm";
import { JoinDiscord } from "../JoinDiscord/JoinDiscord";
import { Icon } from "../Icon/Icon";
import { ICONS } from "../../Constants/icons";

export const Footer = ({ name }) => {
  return (
    <div className={styles.footer} name={name}>
      <ContactForm />
      <JoinDiscord white />
      <Container>
        <div className={styles.footer__wrap}>
          <div className={styles.footer__leftWrap}>
            <div className={styles.footer__menu}>
              <ul className={styles.footer__menu__list}>
                <li className={styles.footer__menu__item}>
                  <a href="/#whyUs">Why Us</a>
                </li>
                <li className={styles.footer__menu__item}>
                  <a href="/#videos">Videos</a>
                </li>
                <li className={styles.footer__menu__item}>
                  <a href="/#features">Features</a>
                </li>
                <li className={styles.footer__menu__item}>
                  <a href="/#contactForm">
                    <span>Join Us!</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.footer__logo}>
              <img
                className={styles.footer__logo__img}
                src={headerLogo}
                alt="logo"
              />
              <h2 className={styles.footer__logo__text}>Boostware</h2>
            </div>
          </div>
          <div className={styles.footer__social}>
            <a href="/#TODO" target="_blank" rel="noreferrer">
              <Icon name={ICONS.social3} />
            </a>
            <a href="/#TODO" target="_blank" rel="noreferrer">
              <Icon name={ICONS.social4} />
            </a>
            <a href="/#TODO" target="_blank" rel="noreferrer">
              <Icon name={ICONS.twitter} />
            </a>
            <a href="/#TODO" target="_blank" rel="noreferrer">
              <Icon name={ICONS.telegram} />
            </a>
            <a href="/#TODO" target="_blank" rel="noreferrer">
              <Icon name={ICONS.discord} />
            </a>
            <a href="/#TODO" target="_blank" rel="noreferrer">
              <Icon name={ICONS.social2} />
            </a>
            <a href="/#TODO" target="_blank" rel="noreferrer">
              <Icon name={ICONS.social1} />
            </a>
          </div>
          <p className={styles.footer__text}>&copy; 2024 Boostware</p>
          <div className={styles.footer__links}>
            <a className={styles.footer__links__link} href="/#TODO">
              Team
            </a>
            <a className={styles.footer__links__link} href="/#TODO">
              Privacy Policy
            </a>
            <a className={styles.footer__links__link} href="/#TODO">
              Terms of Use
            </a>
            <a className={styles.footer__links__link} href="/#TODO">
              Contact Us
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};
