import React from "react";

import { HeaderMenu } from "../HeaderMenu/HeaderMenu";
import headerLogo from "../../assets/images/headerLogo.png";
import headerBg from "../../assets/images/headerBg.png";
import styles from "./styles.module.scss";

export const Header = ({ name }) => {
  return (
    <div className={styles.header} name={name}>
      <img className={styles.header__bg} src={headerBg} alt="Background" />
      <HeaderMenu />

      <div className={styles.header__content} id={"whyUs"}>
        <img
          className={styles.header__content__logo}
          src={headerLogo}
          alt="logo"
        />
        <h1 className={styles.header__content__title}>Boostware</h1>
        <p className={styles.header__content__text}>
          Boostware is developed by gamers who share your goal - to win.
        </p>
        <p className={styles.header__content__text}>
          Our unique features let you beat both regular players and cheaters.
          Join us to enhance your gaming experience, <b>absolutely free!</b>
        </p>

        <a className={styles.header__content__link} href="/#TODO">
          Join Us!
        </a>
      </div>
    </div>
  );
};
