import styles from "./styles.module.scss";
import { Container } from "../Container/Container";
import buttonDiscord from "../../assets/images/joinDiscord.png";
import buttonDiscord2 from "../../assets/images/joinDiscord2.png";

export const JoinDiscord = ({ white, name }) => {
  return (
    <div className={`${styles.join_dis} ${white && styles.white}`} name={name}>
      <Container>
        <div className={styles.join_dis__content}>
          <div className={styles.join_dis__content__item}>
            <a
              className={styles.join_dis__link}
              target="_blank"
              rel="noreferrer"
              href="/#TODO"
            >
              <img
                className={styles.join_dis__img}
                src={white ? buttonDiscord2 : buttonDiscord}
                alt="Join Discord"
              />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};
